<template>
  <div class="logout">
  Logout...
  </div>
</template>

<script>

export default {
    created(){
        window.console.log('Hace logout');
        this.$store.commit('logout');
    }
}
</script>
